import React, { useEffect, useState } from 'react'
import { jsx } from '@emotion/core'

import Layout from '../layout/index'
import SEO from '../components/seo'
import myLabels from '../documents/thaks'
import AppSolidButton from '../components/AppButton/index'
import {
  thanksPage,
  thanksPageInner,
  thanksPageSubTitle,
  thanksPageText
} from '../styles/thanks'

const ThanksPage: React.FC = () => {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    const $header = document.getElementsByTagName('header')[0]
    const $footer = document.getElementsByTagName('footer')[0]

    setHeight(window.innerHeight - $header.clientHeight - $footer.clientHeight)
  }, [])

  return (
    <Layout>
      <SEO title={myLabels.title.sub} noindex={true} />
      <section css={[thanksPage, height ? `height: ${height}px` : '']}>
        <div css={thanksPageInner}>
          <h2 dangerouslySetInnerHTML={{ __html: myLabels.title.main }}></h2>
          <h3 css={thanksPageSubTitle}>{myLabels.title.sub}</h3>
          <p css={thanksPageText} dangerouslySetInnerHTML={{ __html: myLabels.text }}></p>
          <AppSolidButton context={{ path: 'https://hajimjarisocks.com', label: myLabels.button }}></AppSolidButton>
        </div>
      </section>
    </Layout>
  )
}

export default ThanksPage
