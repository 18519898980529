/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { rem, lineHeight } from './style'

export const thanksPage = css`
  position: relative;
  text-align: center;

  @media (max-width: 768px) {
    height: auto;
  }
`

export const thanksPageInner = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media (max-width: 768px) {
    position: inherit;
    top: inherit;
    left: inherit;
    transform: none;
    padding: ${rem(32)} ${rem(16)};
  }

  h2 {
    padding-bottom: ${rem(10)};
    font-size: ${rem(102)};
    font-weight: bold;
    color: #5BBAD5;

    @media (max-width: 768px) {
      font-size: ${rem(76)};
    }
  } 
`

export const thanksPageSubTitle = css`
  padding-bottom: ${rem(24)};
  font-size: ${rem(26)};
  line-height: ${lineHeight(26, 35)};
  font-weight: bold;

  @media (max-width: 768px) {
    padding-bottom: ${rem(20)};
    font-size: ${rem(20)};
  }
`

export const thanksPageText = css`
  padding-bottom: ${rem(60)};
  font-size: ${rem(16)};
  line-height: ${lineHeight(16, 28)};

  @media (max-width: 768px) {
    font-size: ${rem(14)};
    line-height: ${lineHeight(14, 24)};
  }
`
