import React, { memo } from 'react'
import { Link } from 'gatsby'
import { css, jsx } from '@emotion/core'
import { BASE_TEXT_COLOR, BASE_WHITE_COLOR, EASE_IN_OUT_QUART } from '../../styles/.style'
import { rem, lineHeight } from '../../styles/style'

const solidButton = css`
  display: inline-block;
  padding: ${rem(14)} 0 ${rem(17)};
  font-size: ${rem(18)};
  line-height: ${lineHeight(18, 24)};
  font-weight: bold;
  color: ${BASE_TEXT_COLOR};
  width: ${rem(372)};
  border-radius: ${rem(4)};
  border: 1px solid ${BASE_TEXT_COLOR};
  transition: ${EASE_IN_OUT_QUART};

  &:hover {
    color: ${BASE_WHITE_COLOR};
    background: ${BASE_TEXT_COLOR};
  }

  @media (max-width: 768px) {
    padding: ${rem(16)} 0 ${rem(14)};
    font-size: ${rem(16)};
    line-height: ${lineHeight(16, 20)};
    width: 100%;
  }
`

const AppSolidButton = memo(({ context }: any) => (
  <Link to={context.path} css={solidButton}>{context.label}</Link>
))

export default AppSolidButton
